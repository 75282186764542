export const COGNITO_USER_ATTRIBUTES = {
  FAMILY_NAME: 'family_name',
  GIVEN_NAME: 'given_name',
  JUPYTERHUB_USERNAME: 'custom:jupyterhub_username',
};

export const COLUMN_DATA_TYPE_OPTIONS = [
  { label: 'Boolean', value: 'boolean' },
  { label: 'Date', value: 'date' },
  { label: 'Integer', value: 'integer' },
  { label: 'Float', value: 'float' },
  { label: 'String', value: 'string' },
];

export const COLUMN_MAPPING_POLICY_OPTIONS = [
  { label: 'Name Based', value: 'name-based' },
  { label: 'Positional Based', value: 'positional-based' },
];

export const DAG_INTERVAL_UNIT = [
  { label: 'hours', value: 'hours' },
  { label: 'days', value: 'days' },
  { label: 'minutes', value: 'minutes' },
];

export const DATASET_STATUSES = {
  ANNOTATION_REQUIRED: 'annotation-required',
  EMPTY_DATASET: 'empty-dataset',
  INITIALIZED: 'initialized',
  STANDARDIZATION_COMPLETE: 'standardization-complete',
  STANDARDIZATION_FAILED: 'standardization-failed',
  STANDARDIZATION_PENDING: 'standardization-pending',
  UNKNOWN: 'unknown',
  VALIDATION_COMPLETE: 'validation-complete',
  VALIDATION_ERRORED: 'validation-errored',
  VALIDATION_FAILED: 'validation-failed',
  VALIDATION_PENDING: 'validation-pending',
};

export const DATASET_STATUS_LABEL_MAP = {
  [DATASET_STATUSES.UNKNOWN]: 'Unknown',
  [DATASET_STATUSES.INITIALIZED]: 'Initialized',
  [DATASET_STATUSES.ANNOTATION_REQUIRED]: 'Annotation Required',
  [DATASET_STATUSES.STANDARDIZATION_PENDING]: 'Standardization Pending',
  [DATASET_STATUSES.STANDARDIZATION_COMPLETE]: 'Standardization Complete',
  [DATASET_STATUSES.STANDARDIZATION_FAILED]: 'Standardization Failed',
  [DATASET_STATUSES.VALIDATION_PENDING]: 'Validation Pending',
  [DATASET_STATUSES.VALIDATION_COMPLETE]: 'Validation Complete',
  [DATASET_STATUSES.VALIDATION_FAILED]: 'Validation Failed',
  [DATASET_STATUSES.VALIDATION_ERRORED]: 'Validation Errored',
  [DATASET_STATUSES.EMPTY_DATASET]: 'Empty Dataset',
};

export const DATASET_TYPES = {
  SYSTEM_UPLOAD: 'system-uploaded',
  USER_UPLOAD: 'user-uploaded',
};

export const DATE_FORMAT_OPTIONS = [
  { label: 'YYYY-MM-DD', value: 'YYYY-MM-DD' },
  { label: 'YYYY-MM-DDTHH:MM:SS', value: 'YYYY-MM-DDTHH:MM:SS' },
  { label: 'MM/DD/YY', value: 'MM/DD/YY' },
  { label: 'MM/DD/YYYY', value: 'MM/DD/YYYY' },
  { label: 'MM-DD-YYYY', value: 'MM-DD-YYYY' },
  { label: 'DD/MM/YYYY', value: 'DD/MM/YYYY' },
  { label: 'DD-MM-YYYY', value: 'DD-MM-YYYY' },
  { label: 'YYYY/MM/DD', value: 'YYYY/MM/DD' },
];

export const DEFAULT_JUPYTERHUB_USERNAME = 'system';

export const DELIVERY_TYPE_OPTIONS = [
  { label: 'SFTP', value: 'sftp' },
  { label: 'API', value: 'api' },
  { label: 'Manual Upload', value: 'manual-upload' },
];

export const EXECUTION_STATUSES = {
  FAILED: 'failed',
  NOT_STARTED: 'not-started',
  RUNNING: 'running',
  SUCCESS: 'success',
};

export const FILE_FORMAT_OPTIONS = [
  { label: 'AVRO', value: 'avro' },
  { label: 'BBA', value: 'bba' },
  { label: 'CSV', value: 'csv' },
  { label: 'DNLite', value: 'dnlite' },
  { label: 'EDI - 834', value: 'edi' },
  { label: 'ECSV', value: 'ecsv' },
  { label: 'ENAV (BEM)', value: 'enavbem' },
  { label: 'Industry Standard EDI', value: 'isedi' },
  { label: 'JSON', value: 'json' },
  { label: 'LDEX (BEM)', value: 'ldexbem' },
  { label: 'PARQUET', value: 'parquet' },
  { label: 'TSV', value: 'tsv' },
  { label: 'TXT', value: 'txt' },
  { label: 'XLS', value: 'xls' },
  { label: 'XLSX', value: 'xlsx' },
  { label: 'XML', value: 'xml' },
];

export const IS_BROWSER = typeof window !== 'undefined' && window;

export const JSON_SCHEMA_FORMAT_OPTIONS = [
  { label: 'Currency ($xx.yy)', value: 'currency' },
  { label: 'Date-time (YYYY-MM-DDTHH:mm:ss+zone)', value: 'date-time' },
  { label: 'Date (YYYY-MM-DD)', value: 'date' },
  { label: 'Email (local-part@domain.com)', value: 'email' },
  { label: 'Hostname (xxx.yyy.zzz.lll)', value: 'hostname' },
  { label: 'Idn-email (international-format@domain.com)', value: 'idn-email' },
  { label: 'Idn-hostname (xxx.yyy.zzz.lll)', value: 'idn-hostname' },
  { label: 'Iri (Internationalized Resource Identifiers)', value: 'iri' },
  {
    label:
      'Iri-reference (Internationalized Resource Identifiers for uri-reference)',
    value: 'iri-reference',
  },
  { label: 'Json-pointer (/foo/bar)', value: 'json-pointer' },
  { label: 'Ipv4 (a.b.c.d)', value: 'ipv4' },
  { label: 'Ipv6 (a:b:c:d:e:f:g:h)', value: 'ipv6' },
  {
    label: 'Relative-json-pointer (relative to json-pointer)',
    value: 'relative-json-pointer',
  },
  { label: 'Time (HH:mm:ss+zone)', value: 'time' },
  { label: 'Uri ("dffdfdfdf" - physical resource)', value: 'uri' },
  { label: 'Uri-reference (URI / relative-ref)', value: 'uri-reference' },
  { label: 'Uri-template (template like RFC 6570)', value: 'uri-template' },
];

export const PAGINATION_PARAMS = {
  OFFSET: 'page',
};

export const PERMISSION_ACTIONS = {
  A: 'A',
  D: 'D',
  R: 'R',
  W: 'W',
  X: 'X',
};

export const PERMISSION_ACTIONS_MAP = {
  _: 'Disabled',
  R: 'Read',
  RA: 'Admin',
  RD: 'Read & Download Errors',
  RDW: 'Read, Download Errors & Write',
  RW: 'Read & Write',
  RWX: 'Read, Write & Execute',
  RX: 'Read & Execute',
};

export const PERMISSION_RESOURCES = {
  DATASTORES: 'datastores',
  HOME: 'home',
  USERS: 'users',
  WORK_QUEUE: 'work-queue',
};

export const PIPELINE_INPUT_TYPES = {
  BOOLEAN: 'Boolean',
  COLUMN: 'Column',
  DATASET: 'Dataset',
  DICTIONARY: 'Dictionary',
  FLOAT: 'Float',
  INTEGER: 'Integer',
  NEW_COLUMN: 'NewColumn',
  S3Path: 'S3Path',
  SCHEMA: 'Schema',
  STRING: 'String',
};

export const PIPELINE_STEP_INPUT_TYPES = {
  ...PIPELINE_INPUT_TYPES,
  AGGREGATE: 'Aggregate',
  COLUMN_DICTIONARY: 'ColumnDictionary',
  COLUMN_LIST: 'ColumnList',
  CUSTOM: 'Custom',
  DATE: 'Date',
  ENTITY_CONFIG_DICTIONARY: 'EntityConfigDict',
  JSON: 'Json',
  LIST: 'List',
  REVERSED_COLUMN_DICTIONARY: 'ReversedColumnDictionary',
  RULESET: 'RuleSet',
  TAGS: 'Tags',
  VALUE_CONFIG_KEY_PATHS: 'ValueConfigKeyPaths',
  VIEW_CONFIG_DICTIONARY: 'ViewConfigDict',
};

export const PIPELINE_STEP_INPUT_TYPE_DEFAULT_VALUES = {
  [PIPELINE_STEP_INPUT_TYPES.BOOLEAN]: false,
  [PIPELINE_STEP_INPUT_TYPES.COLUMN]: '',
  [PIPELINE_STEP_INPUT_TYPES.COLUMN_DICTIONARY]: {},
  [PIPELINE_STEP_INPUT_TYPES.DATASET]: '',
  [PIPELINE_STEP_INPUT_TYPES.DICTIONARY]: {},
  [PIPELINE_STEP_INPUT_TYPES.INTEGER]: null,
  [PIPELINE_STEP_INPUT_TYPES.SCHEMA]: '',
  [PIPELINE_STEP_INPUT_TYPES.STRING]: '',
  [PIPELINE_STEP_INPUT_TYPES.TAGS]: {},
};

export const PIPELINE_STEP_STATES = {
  ARCHIVED: 'archived',
  FAILED: 'failed',
  RUNNING: 'running',
  SUCCESS: 'success',
  UPSTREAM_FAILED: 'upstream_failed',
};

export const PIPELINE_STEP_TYPES = {
  CASE_RULES: 'caserules',
  COLUMN_MAPPER: 'columnMapper',
  DENORMALIZER: 'denormalizer',
  NORMALIZER: 'normalizer',
  RUN_NOTEBOOK: 'runNotebook',
  SCHEMA_VERIFICATION: 'schemaVerification',
  VALUE_MAPPER: 'valueMapper',
};

export const PIPELINE_TYPES = {
  DATA_EXCHANGE: 'dex',
};

export const PROTOCOLS = [
  { label: 'SFTP', value: 'sftp' },
  { label: 'S3', value: 's3' },
];

export const REGEXES = {
  END_OF_FORM_NAME: /\.[^.]+$/,
  UUID: '[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}',
  VERSION_NUMBER: '[0-9.]+',
};

export const SEARCH_PARAMS = {
  CREATED: 'created',
  DATASET_STATUS: 'datasetStatus',
  DATASET_TYPE: 'datasetType',
  EXECUTION_STATUS: 'executionStatus',
  IS_PUBLISHED: 'isPublished',
  NAME: 'name',
  PIPELINE_ID: 'pipelineId',
  STARTED_BY: 'startedBy',
  STATUS: 'status',
  TAGS: 'tags',
  VALIDATION_STATUS: 'validationStatus',
};

export const SEARCH_TYPES = {
  ERROR_MESSAGE: 'errorMessage',
  NAME: 'name',
  STATUS: 'status',
  TAG: 'tag',
};

export const SORT_ORDERS = {
  ASC: 'asc',
  DESC: 'desc',
};

export const SORT_PARAMS = {
  ORDER_BY: 'orderBy',
  SORT_BY: 'sortBy',
};

export const VALUE_MAPPER_CATEGORY = {
  ACTION: 'action',
  AGGREGATE: 'aggregate',
  CONDITION: 'condition',
};

export const YES_NO_OPTIONS = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
];

export const UUID_PREFIXES = {
  CASE: 'spr:bz:case::',
  CLIENT: 'spr:clt::',
  DATASET: 'spr:bz:ds::',
  SCHEMA: 'spr:bz:schema::',
  USER: 'spr:user::',
};

export const BAR_PADDING = 0.3;

export const DEFAULT_SCALE = 1;
export const DEFAULT_OPACITY = 1;
export const HOVER_SCALE = 1.08;
export const HOVER_OPACITY = 0.4;

export const BUSINESS_UNIT_MAPPING = {
  'client-tech-business-dev': 'Client Tech Business Dev',
  'medical-underwriting': 'Medical Underwriting',
  'member-maintenance': 'Member Maintenance',
  'new-business': 'New Business',
};

export const BUSINESS_UNIT_TAGS = {
  'client-tech-business-dev': 'CTECH',
  'medical-underwriting': 'MUW',
  'member-maintenance': 'MM',
  'new-business': 'NB',
};

export const USE_CASE_MAPPING = {
  834: 'Edi 834',
  CEN: 'Census File',
  CHG: 'Change File',
  DNL: 'Dnlite',
  ENAV: 'Employee Navigator',
  EOI: 'Evidence of Insurability File',
  LDEX: 'LDEX',
  REF: 'Reference File',
};

export const DATA_TYPE = [
  { label: 'Param', value: 'param' },
  { label: 'Metric', value: 'metric' },
  { label: 'Artifact', value: 'artifact' },
];
export const DATA_VALUE_TYPE = [
  { label: 'Float', value: 'float' },
  { label: 'String', value: 'str' },
  { label: 'Integer', value: 'int' },
  { label: 'URL', value: 'url' },
  { label: 'Matrix', value: 'matrix' },
];

export const TIME_FRAME = [
  { label: 'Last 7 days', value: 'days_7' },
  { label: 'Last 14 days', value: 'days_14' },
  { label: 'Last 30 days', value: 'days_30' },
  { label: 'Last 3 months', value: 'months_3' },
  { label: 'Last 6 months', value: 'months_6' },
];
