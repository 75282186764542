import PropTypes from 'prop-types';
import Input from '@base/Input';
import React, { useState } from 'react';
import { FORM_ERROR } from 'final-form';
import { Field, Form as FinalForm } from 'react-final-form';
import {
  composeValidations,
  match,
  minLength,
  required,
} from '@utils/validations';
import { get } from 'lodash';
import FormError from '@components/FormError';
import { minPasswordLength } from '@config';
import PasswordValidator from '@layout/App/Auth/PasswordValidator';
import { Box } from 'reflexbox';
import SubmitButton from '../SubmitButton';

const labelSx = { color: 'white' };

function CompleteSignup({ completeNewPasswordChallenge }) {
  const [name, setName] = useState(null);
  return (
    <FinalForm
      initialValues={{}}
      onSubmit={async (values) => {
        try {
          if (name) {
            await completeNewPasswordChallenge({
              familyName: values.familyName,
              givenName: values.givenName,
              password: values.password,
            });
          } else {
            setName(values);
          }
        } catch (e) {
          return {
            [FORM_ERROR]: get(e, 'graphQLErrors[0].message', e.message),
          };
        }
      }}
      render={({ handleSubmit, submitError, submitting, values }) => (
        <form onSubmit={handleSubmit}>
          {!name && (
            <>
              <Field
                component={Input}
                label="First Name"
                labelSx={labelSx}
                name="givenName"
                placeholder="First Name"
                validate={required}
              />
              <Field
                component={Input}
                label="Last Name"
                labelSx={labelSx}
                name="familyName"
                placeholder="Last Name"
                validate={required}
              />
            </>
          )}
          {name && (
            <>
              <PasswordValidator password={values?.password} />
              <Field
                component={Input}
                label="New Password"
                labelSx={labelSx}
                name="password"
                placeholder="New Password"
                type="password"
                validate={composeValidations(
                  minLength(minPasswordLength),
                  required
                )}
              />
              <Field
                component={Input}
                label="Verify Password"
                labelSx={labelSx}
                name="verifiedPassword"
                placeholder="Verify Password"
                type="password"
                validate={match('Passwords', values.password)}
              />
            </>
          )}
          <FormError>{submitError}</FormError>
          <Box
            sx={{
              button: {
                margin: '2rem 0',
              },
            }}
          >
            <SubmitButton
              submitting={submitting}
              text={name ? 'Complete Signup' : 'Next'}
            />
          </Box>
        </form>
      )}
    />
  );
}

CompleteSignup.propTypes = {
  completeNewPasswordChallenge: PropTypes.func.isRequired,
};

export default CompleteSignup;
