import React from 'react';
import PropTypes from 'prop-types';
import { ParentSize } from '@vx/responsive';
import Box from '@base/Box';
import { background } from '@config';
import Logo from '@components/Logo';

function Background({ children, title }) {
  return (
    <Box height="100%" sx={{ position: 'absolute' }} width="100%">
      <ParentSize>
        {({ height, width }) => (
          <>
            <Box
              alignItems="center"
              as="div"
              display="flex"
              height={height}
              justifyContent="center"
              style={{
                backgroundImage: `url(/Images/${background})`,
                backgroundSize: 'cover',
              }}
              width={width}
            />
            <Box
              alignItems="center"
              display="flex"
              height={height}
              justifyContent="center"
              opacity="85%"
              sx={{ left: 0, position: 'fixed', top: 0 }}
              width={width}
            >
              <Box bg="primaryDark" height="100%" width={600} />
            </Box>
            <Box
              alignItems="center"
              display="flex"
              height="100%"
              justifyContent="center"
              sx={{ left: 0, position: 'fixed', top: 0 }}
              width={width}
            >
              <Box p={5} width="maxWidths.smallForm">
                <Box color="white" my={4}>
                  <Logo width={90} />
                  <Box color="white" fontWeight="xbold">
                    <Box>EMPLOYER</Box>
                    <Box>CONNECT</Box>
                  </Box>
                </Box>
                {title && (
                  <Box as="h1" color="accentLight" fontSize={5} mb={4}>
                    {title}
                  </Box>
                )}
                {children}
              </Box>
            </Box>
          </>
        )}
      </ParentSize>
    </Box>
  );
}

Background.defaultProps = {
  children: null,
};

Background.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
};

export default Background;
