import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Box from '@base/Box';
import { ThemeContext } from 'styled-components';
import { PulseLoader } from 'react-spinners';
import { get } from 'lodash';

function Spinner({ color, text }) {
  const { colors } = useContext(ThemeContext);
  return (
    <Box
      alignItems="flex-end"
      color={color}
      display="flex"
      justifyContent="center"
      p={7}
    >
      <Box fontSize={5} fontWeight="light">
        {text}
      </Box>
      <PulseLoader color={get(colors, color)} margin={2} size={10} />
    </Box>
  );
}

Spinner.defaultProps = {
  color: 'grays.4',
  text: 'Loading',
};

Spinner.propTypes = {
  color: PropTypes.string,
  text: PropTypes.string,
};

export default Spinner;
