import { PERMISSION_RESOURCES } from './constants';

const isPermitted = ({
  desiredAction,
  desiredResource,
  isAdmin,
  permissions,
}) => {
  try {
    if (isAdmin || desiredResource === PERMISSION_RESOURCES.HOME) return true;
    const permission = permissions.find((p) => p.resource === desiredResource);
    if (!permission) return false;
    return permission.actions.includes(desiredAction);
  } catch (e) {
    return false;
  }
};

export default isPermitted;
