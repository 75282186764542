import { get } from 'lodash';
import { useSnackbar as useReactSimpleSnackbar } from 'react-simple-snackbar';
import { useTheme } from 'styled-components';

const useSnackbar = ({ color } = {}) => {
  const theme = useTheme();

  return useReactSimpleSnackbar({
    position: 'bottom',
    style: {
      backgroundColor: color
        ? get(theme.colors, color, theme.colors.accent)
        : theme.colors.accent,
      borderRadius: theme.radii[1],
      boxShadow: theme.shadows[2],
      fontFamily: theme.fonts.primary,
      fontSize: theme.fontSizes[2],
    },
  });
};

export default useSnackbar;
