import React from 'react';
import PropTypes from 'prop-types';
import { checkIfStrongPass } from '@utils/validations';
import { Box } from 'reflexbox';
import Icon from '@components/Icon';
import { minPasswordLength } from '@config';

function PasswordValidator({ password }) {
  const isPasswordValid = checkIfStrongPass(password);
  const labels = [
    { key: 'isLengthValid', text: `${minPasswordLength} characters` },
    { key: 'hasLowercase', text: '1 lowercase character' },
    { key: 'hasUppercase', text: '1 uppercase character' },
    { key: 'hasDigit', text: '1 digit' },
    { key: 'hasSpecialCharacter', text: '1 special character' },
  ];
  const labelSx = {
    alignItems: 'center',
    color: 'white',
    display: 'flex',
    fontSize: 0,
    fontWeight: 'bold',
    ml: 1,
    textTransform: 'uppercase',
  };
  const iconSx = { alignSelf: 'center', display: 'flex' };

  return (
    <>
      <Box sx={{ color: 'white' }}>Password must at least contain:</Box>
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        {labels.map(({ key, text }) => {
          const icon = isPasswordValid[key] ? 'done-circle' : 'fail';
          const color = isPasswordValid[key] ? 'successDark' : 'errorDark';
          return (
            <Box
              key={key}
              sx={{ display: 'flex', flex: '0 0 50%', my: 2, pl: 1 }}
            >
              <Icon
                color={color}
                height="1.2rem"
                svg={icon}
                sx={iconSx}
                width="1.2rem"
              />
              <Box sx={labelSx}>{text}</Box>
            </Box>
          );
        })}
      </Box>
    </>
  );
}

PasswordValidator.propTypes = {
  password: PropTypes.string,
};
PasswordValidator.defaultProps = {
  password: null,
};
export default PasswordValidator;
