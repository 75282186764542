import React from 'react';
import PropTypes from 'prop-types';
import Box from '@base/Box';
import { SIDEBAR_CLOSE_WIDTH } from '../utils/constants';

function Content({ children }) {
  return (
    <Box key="co" pl={SIDEBAR_CLOSE_WIDTH}>
      <Box key="ci" p={6}>
        {children}
      </Box>
    </Box>
  );
}

Content.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Content;
