import PropTypes from 'prop-types';
import {
  composeValidations,
  different,
  match,
  minLength,
  required,
} from '@utils/validations';
import { FORM_ERROR } from 'final-form';
import React, { useState } from 'react';
import { get } from 'lodash';
import { Field, Form as FinalForm } from 'react-final-form';
import FormError from '@components/FormError';
import Input from '@base/Input';
import { minPasswordLength } from '@config';
import useSnackbar from '@src/utilities/use-snackbar';
import PasswordValidator from '@layout/App/Auth/PasswordValidator';
import { INCORRECT_CREDENTIALS } from '@layout/App/Auth/utils/constants';
import { Box } from 'reflexbox';
import Button from '@base/Button';
import SubmitButton from '../SubmitButton';

const labelSx = { color: 'white' };

function PasswordChange({ changePassword, signOut }) {
  const [setSnack] = useSnackbar();
  const [oldPassword, setOldPassword] = useState(null);

  return (
    <FinalForm
      onSubmit={async (values) => {
        try {
          if (oldPassword) {
            await changePassword(values.oldPassword, values.newPassword);
            setSnack('Password successfully changed.');
          } else {
            setOldPassword(values.oldPassword);
          }
        } catch (e) {
          const errorMessage = get(e, 'graphQLErrors[0].message', e.message);
          if (errorMessage === INCORRECT_CREDENTIALS) {
            setOldPassword(null);
          }
          return {
            [FORM_ERROR]: errorMessage,
          };
        }
        return null;
      }}
      render={({ handleSubmit, submitError, submitting, values }) => (
        <form onSubmit={handleSubmit}>
          {!oldPassword && (
            <Field
              component={Input}
              label="Current Password"
              labelSx={labelSx}
              name="oldPassword"
              type="password"
              validate={composeValidations(
                minLength(minPasswordLength),
                required
              )}
            />
          )}
          {oldPassword && (
            <>
              <PasswordValidator password={values?.newPassword} />
              <Field
                component={Input}
                label="New Password"
                labelSx={labelSx}
                name="newPassword"
                type="password"
                validate={composeValidations(
                  minLength(minPasswordLength),
                  different(values.oldPassword),
                  required
                )}
                wrapperSx={{ mt: 1 }}
              />
              <Field
                component={Input}
                disabled={submitting}
                label="Verify Password"
                labelSx={labelSx}
                name="verifiedPassword"
                type="password"
                validate={composeValidations(
                  match('Passwords', values.newPassword),
                  required
                )}
              />
            </>
          )}

          <FormError>{submitError}</FormError>
          <Box
            sx={{
              button: {
                margin: '2rem 0',
              },
            }}
          >
            <SubmitButton
              submitting={submitting}
              text={oldPassword ? 'Change Password' : 'Next'}
            />
          </Box>
          <Button
            color="accentLight"
            onClick={() => signOut()}
            simple
            sx={{
              float: 'right',
              fontSize: 2,
              mb: 4,
            }}
          >
            Logout
          </Button>
        </form>
      )}
    />
  );
}

PasswordChange.propTypes = {
  changePassword: PropTypes.func.isRequired,
  signOut: PropTypes.func.isRequired,
};
export default PasswordChange;
