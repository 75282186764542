import PropTypes from 'prop-types';
import React from 'react';
import Box from '../../Box';

function TooltipTrigger({ children, id, ...rest }) {
  return (
    <Box>
      {children && (
        <Box data-html data-tooltip-id={id} {...rest}>
          {children}
        </Box>
      )}
    </Box>
  );
}

TooltipTrigger.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string.isRequired,
};

TooltipTrigger.defaultProps = {
  children: null,
};

export default TooltipTrigger;
