import PropTypes from 'prop-types';
import React from 'react';
import Box from '@base/Box';

function FormError({ children, ...rest }) {
  return children ? (
    <Box color="error" mt="md" textAlign="center" {...rest}>
      {children}
    </Box>
  ) : null;
}

FormError.propTypes = {
  children: PropTypes.string,
};

FormError.defaultProps = {
  children: null,
};

export default FormError;
