import PropTypes from 'prop-types';
import React from 'react';
import { validateInput } from '@utils/validations';
import Box from '../Box';
import InputWrapper from '../InputWrapper';

function Input({ input, ...rest }) {
  return (
    <InputWrapper input={input} validate={validateInput} {...rest}>
      {({ error, ...inputRest }) => (
        <Box
          as="input"
          id={input.name}
          sx={{
            borderColor: error ? 'error' : null,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          variant="inputs.primary"
          width="100%"
          {...inputRest}
          {...input}
        />
      )}
    </InputWrapper>
  );
}

Input.propTypes = {
  input: PropTypes.shape({ error: PropTypes.bool, name: PropTypes.string })
    .isRequired,
};

export default Input;
