import PropTypes from 'prop-types';
import React from 'react';
import { Tooltip } from 'react-tooltip';
import Box from '../../Box';
import 'react-tooltip/dist/react-tooltip.css';

function TooltipBox({ id, tooltipProps }) {
  return (
    <Box
      as={Tooltip}
      id={id}
      sx={{
        borderRadius: 1,
        opacity: 1,
      }}
      {...tooltipProps}
    />
  );
}

TooltipBox.propTypes = {
  id: PropTypes.string.isRequired,
  tooltipProps: PropTypes.shape({ sx: PropTypes.shape({}) }),
};

TooltipBox.defaultProps = {
  tooltipProps: {},
};

export default TooltipBox;
