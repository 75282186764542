// NOTE: this file is generated automatically, any changes will be overwritten.

import Box from '@base/Box';
import PropTypes from 'prop-types';
import React from 'react';
import account from '../../images/icons/account.svg';
import addcomment from '../../images/icons/add-comment.svg';
import arrowback from '../../images/icons/arrow-back.svg';
import arrowdown from '../../images/icons/arrow-down.svg';
import assignment from '../../images/icons/assignment.svg';
import associate from '../../images/icons/associate.svg';
import build from '../../images/icons/build.svg';
import calendarend from '../../images/icons/calendar-end.svg';
import calendarmonth from '../../images/icons/calendar-month.svg';
import calendarstart from '../../images/icons/calendar-start.svg';
import check from '../../images/icons/check.svg';
import checkbox from '../../images/icons/checkbox.svg';
import chevron from '../../images/icons/chevron.svg';
import clock from '../../images/icons/clock.svg';
import close from '../../images/icons/close.svg';
import connectorfilled from '../../images/icons/connector-filled.svg';
import connectoroutline from '../../images/icons/connector-outline.svg';
import copy from '../../images/icons/copy.svg';
import datasetfilled from '../../images/icons/dataset-filled.svg';
import datasetoutline from '../../images/icons/dataset-outline.svg';
import datastoresfilled from '../../images/icons/datastores-filled.svg';
import datastoresoutline from '../../images/icons/datastores-outline.svg';
import datumfilled from '../../images/icons/datum-filled.svg';
import datumoutline from '../../images/icons/datum-outline.svg';
import donecircle from '../../images/icons/done-circle.svg';
import done from '../../images/icons/done.svg';
import dots from '../../images/icons/dots.svg';
import download from '../../images/icons/download.svg';
import edit from '../../images/icons/edit.svg';
import error from '../../images/icons/error.svg';
import fail from '../../images/icons/fail.svg';
import filefilled from '../../images/icons/file-filled.svg';
import fileoutline from '../../images/icons/file-outline.svg';
import filetemplatefilled from '../../images/icons/file-template-filled.svg';
import filetemplateoutline from '../../images/icons/file-template-outline.svg';
import filteroutline from '../../images/icons/filter-outline.svg';
import filter from '../../images/icons/filter.svg';
import fineosmono from '../../images/icons/fineos-mono.svg';
import help from '../../images/icons/help.svg';
import homefilled from '../../images/icons/home-filled.svg';
import homeoutline from '../../images/icons/home-outline.svg';
import hourglassbottom from '../../images/icons/hourglass-bottom.svg';
import hourglasstop from '../../images/icons/hourglass-top.svg';
import info from '../../images/icons/info.svg';
import leftchevron from '../../images/icons/left-chevron.svg';
import lists from '../../images/icons/lists.svg';
import locked from '../../images/icons/locked.svg';
import loggerfilled from '../../images/icons/logger-filled.svg';
import loggeroutline from '../../images/icons/logger-outline.svg';
import logout from '../../images/icons/logout.svg';
import menu from '../../images/icons/menu.svg';
import modelfilled from '../../images/icons/model-filled.svg';
import modeloutline from '../../images/icons/model-outline.svg';
import navigatenext from '../../images/icons/navigate-next.svg';
import newreleases from '../../images/icons/new-releases.svg';
import noencryption from '../../images/icons/no-encryption.svg';
import pause from '../../images/icons/pause.svg';
import pending from '../../images/icons/pending.svg';
import play from '../../images/icons/play.svg';
import plus from '../../images/icons/plus.svg';
import prediction from '../../images/icons/prediction.svg';
import refresh from '../../images/icons/refresh.svg';
import repeat from '../../images/icons/repeat.svg';
import rightchevron from '../../images/icons/right-chevron.svg';
import rolefilled from '../../images/icons/role-filled.svg';
import roleoutline from '../../images/icons/role-outline.svg';
import schemafilled from '../../images/icons/schema-filled.svg';
import schemaoutline from '../../images/icons/schema-outline.svg';
import scsmappingfilled from '../../images/icons/scs-mapping-filled.svg';
import scsmappingoutline from '../../images/icons/scs-mapping-outline.svg';
import source from '../../images/icons/source.svg';
import tag from '../../images/icons/tag.svg';
import trash from '../../images/icons/trash.svg';
import uncheck from '../../images/icons/uncheck.svg';
import unlocked from '../../images/icons/unlocked.svg';
import uploadcloud from '../../images/icons/upload-cloud.svg';
import upload from '../../images/icons/upload.svg';
import usercheck from '../../images/icons/user-check.svg';
import userfilled from '../../images/icons/user-filled.svg';
import useroutline from '../../images/icons/user-outline.svg';
import usersadddisabled from '../../images/icons/users-add-disabled.svg';
import usersadd from '../../images/icons/users-add.svg';
import usersgear from '../../images/icons/users-gear.svg';
import usersremove from '../../images/icons/users-remove.svg';
import verified from '../../images/icons/verified.svg';
import view from '../../images/icons/view.svg';
import workflowfilled from '../../images/icons/workflow-filled.svg';
import workflowoutline from '../../images/icons/workflow-outline.svg';

const svgMap = {
  account: account,
  'add-comment': addcomment,
  'arrow-back': arrowback,
  'arrow-down': arrowdown,
  assignment: assignment,
  associate: associate,
  build: build,
  'calendar-end': calendarend,
  'calendar-month': calendarmonth,
  'calendar-start': calendarstart,
  check: check,
  checkbox: checkbox,
  chevron: chevron,
  clock: clock,
  close: close,
  'connector-filled': connectorfilled,
  'connector-outline': connectoroutline,
  copy: copy,
  'dataset-filled': datasetfilled,
  'dataset-outline': datasetoutline,
  'datastores-filled': datastoresfilled,
  'datastores-outline': datastoresoutline,
  'datum-filled': datumfilled,
  'datum-outline': datumoutline,
  'done-circle': donecircle,
  done: done,
  dots: dots,
  download: download,
  edit: edit,
  error: error,
  fail: fail,
  'file-filled': filefilled,
  'file-outline': fileoutline,
  'file-template-filled': filetemplatefilled,
  'file-template-outline': filetemplateoutline,
  'filter-outline': filteroutline,
  filter: filter,
  'fineos-mono': fineosmono,
  help: help,
  'home-filled': homefilled,
  'home-outline': homeoutline,
  'hourglass-bottom': hourglassbottom,
  'hourglass-top': hourglasstop,
  info: info,
  'left-chevron': leftchevron,
  lists: lists,
  locked: locked,
  'logger-filled': loggerfilled,
  'logger-outline': loggeroutline,
  logout: logout,
  menu: menu,
  'model-filled': modelfilled,
  'model-outline': modeloutline,
  'navigate-next': navigatenext,
  'new-releases': newreleases,
  'no-encryption': noencryption,
  pause: pause,
  pending: pending,
  play: play,
  plus: plus,
  prediction: prediction,
  refresh: refresh,
  repeat: repeat,
  'right-chevron': rightchevron,
  'role-filled': rolefilled,
  'role-outline': roleoutline,
  'schema-filled': schemafilled,
  'schema-outline': schemaoutline,
  'scs-mapping-filled': scsmappingfilled,
  'scs-mapping-outline': scsmappingoutline,
  source: source,
  tag: tag,
  trash: trash,
  uncheck: uncheck,
  unlocked: unlocked,
  'upload-cloud': uploadcloud,
  upload: upload,
  'user-check': usercheck,
  'user-filled': userfilled,
  'user-outline': useroutline,
  'users-add-disabled': usersadddisabled,
  'users-add': usersadd,
  'users-gear': usersgear,
  'users-remove': usersremove,
  verified: verified,
  view: view,
  'workflow-filled': workflowfilled,
  'workflow-outline': workflowoutline,
};

const Icon = ({ svg, ...rest }) => {
  const SvgComponent = svgMap[svg];

  return (
    <Box {...rest}>
      <SvgComponent />
    </Box>
  );
};

Icon.propTypes = {
  svg: PropTypes.oneOf([
    'account',
    'add-comment',
    'arrow-back',
    'arrow-down',
    'assignment',
    'associate',
    'build',
    'calendar-end',
    'calendar-month',
    'calendar-start',
    'check',
    'checkbox',
    'chevron',
    'clock',
    'close',
    'connector-filled',
    'connector-outline',
    'copy',
    'dataset-filled',
    'dataset-outline',
    'datastores-filled',
    'datastores-outline',
    'datum-filled',
    'datum-outline',
    'done-circle',
    'done',
    'dots',
    'download',
    'edit',
    'error',
    'fail',
    'file-filled',
    'file-outline',
    'file-template-filled',
    'file-template-outline',
    'filter-outline',
    'filter',
    'fineos-mono',
    'help',
    'home-filled',
    'home-outline',
    'hourglass-bottom',
    'hourglass-top',
    'info',
    'left-chevron',
    'lists',
    'locked',
    'logger-filled',
    'logger-outline',
    'logout',
    'menu',
    'model-filled',
    'model-outline',
    'navigate-next',
    'new-releases',
    'no-encryption',
    'pause',
    'pending',
    'play',
    'plus',
    'prediction',
    'refresh',
    'repeat',
    'right-chevron',
    'role-filled',
    'role-outline',
    'schema-filled',
    'schema-outline',
    'scs-mapping-filled',
    'scs-mapping-outline',
    'source',
    'tag',
    'trash',
    'uncheck',
    'unlocked',
    'upload-cloud',
    'upload',
    'user-check',
    'user-filled',
    'user-outline',
    'users-add-disabled',
    'users-add',
    'users-gear',
    'users-remove',
    'verified',
    'view',
    'workflow-filled',
    'workflow-outline',
  ]).isRequired,
};

export default Icon;
