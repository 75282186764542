import PropTypes from 'prop-types';
import { composeValidations, email, required } from '@utils/validations';
import { FORM_ERROR } from 'final-form';
import React from 'react';
import { get } from 'lodash';
import { Field, Form as FinalForm } from 'react-final-form';
import FormError from '@components/FormError';
import Button from '@base/Button';
import Input from '@base/Input';
import SubmitButton from '../SubmitButton';

const labelSx = { color: 'white' };

function Login({ setShowPasswordResetPage, signIn }) {
  return (
    <FinalForm
      onSubmit={async (values) => {
        try {
          await signIn(values);
        } catch (e) {
          return {
            [FORM_ERROR]: get(e, 'graphQLErrors[0].message', e.message),
          };
        }
        return null;
      }}
      render={({ handleSubmit, submitError, submitting }) => (
        <form onSubmit={handleSubmit}>
          <Field
            component={Input}
            label="Email"
            labelSx={labelSx}
            name="username"
            validate={composeValidations(required, email)}
          />
          <Field
            component={Input}
            label="Password"
            labelSx={labelSx}
            name="password"
            type="password"
            validate={required}
          />
          <FormError>{submitError}</FormError>
          <Button
            color="accentLight"
            onClick={() => setShowPasswordResetPage()}
            simple
            sx={{
              float: 'right',
              fontSize: 2,
              my: 3,
            }}
          >
            Forgot password?
          </Button>
          <SubmitButton submitting={submitting} text="Sign In" />
        </form>
      )}
    />
  );
}

Login.propTypes = {
  setShowPasswordResetPage: PropTypes.func.isRequired,
  signIn: PropTypes.func.isRequired,
};
export default Login;
