import 'core-js/stable';
import 'isomorphic-unfetch';
import PropTypes from 'prop-types';
import React from 'react';
import SnackbarProvider from 'react-simple-snackbar';
import { ThemeProvider } from 'styled-components';
import theme from '@src/theme';
import ErrorBoundary from '@base/ErrorBoundary';
import StyledGlobal from '@base/StyledGlobal';
// does your component require a css import? put it here...
// https://github.com/webpack-contrib/mini-css-extract-plugin/issues/382
import '@brainhubeu/react-carousel/lib/style.css';
import '@wojtekmaj/react-daterange-picker';
import 'filepond/dist/filepond.min.css';
import { AuthProvider } from './Auth';

function App({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <StyledGlobal />
      <SnackbarProvider>
        <ErrorBoundary>
          <AuthProvider>{children}</AuthProvider>
        </ErrorBoundary>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

App.propTypes = {
  children: PropTypes.node.isRequired,
};

export default App;
