import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@base/Box';
import Logo from '@components/Logo';
import { navigate } from 'gatsby';
import { envName, moduleMapping, navLinks } from '@config';
import { get } from 'lodash';
import isPermitted from '@src/utilities/is-permitted';
import { PERMISSION_ACTIONS } from '@src/utilities/constants';
import { useLocation } from '@reach/router';
import { useIdleTimer } from 'react-idle-timer';
import {
  LOGO_WIDTH,
  SIDEBAR_CLOSE_WIDTH,
  SIDEBAR_OPEN_WIDTH,
  TRANSITION,
} from '../utils/constants';
import DrawerItem from './DrawerItem';

function Drawer({ signOut, user }) {
  const [open, setOpen] = useState(false);
  const [state, setState] = useState('active');
  const location = useLocation();

  useIdleTimer({
    onActive: () => setState('active'),
    onIdle: () => setState('idle'),
    timeout: 1000 * 30,
  });

  return (
    <Box
      alignItems="flex-start"
      bg={open ? 'primaryDark' : 'primary'}
      display="flex"
      flexDirection="column"
      height="100%"
      justifyContent="flex-start"
      onMouseLeave={() => setOpen(false)}
      onMouseOver={() => setOpen(true)}
      sx={{
        borderBottomRightRadius: '10%',
        borderTopRightRadius: '10%',
        position: 'fixed',
        transition: TRANSITION,
        zIndex: 3,
      }}
      variant={`gradients.drawer.${state}`}
      width={{
        _: SIDEBAR_CLOSE_WIDTH,
        md: open ? SIDEBAR_OPEN_WIDTH : SIDEBAR_CLOSE_WIDTH,
      }}
    >
      <Box
        color="white"
        display="flex"
        flexDirection="column"
        fontSize={1}
        fontWeight="xbold"
        justifyContent="flex-start"
        my={2}
        p={2}
      >
        <Logo
          onClick={() => navigate('/home/')}
          sx={{ cursor: 'pointer' }}
          width={LOGO_WIDTH}
        />

        <Box>EMPLOYER</Box>
        <Box>CONNECT</Box>

        {envName && (
          <Box color="primaryDark" my={4} sx={{ textTransform: 'uppercase' }}>
            {envName}
          </Box>
        )}
      </Box>

      <Box bg="primary" px={4} width="100%">
        <DrawerItem
          icon="account"
          label={`${get(user, 'givenName')} ${get(user, 'familyName')}`}
          open={open}
        />
        <DrawerItem
          icon="logout"
          label="Log Out"
          onClick={() => signOut()}
          open={open}
        />
      </Box>

      <Box overflowY="auto" px={4} width="100%">
        {navLinks
          .filter(({ adminOnly, hidden, permissionResource }) => {
            if (hidden) return false;
            if (adminOnly && !user.isAdmin) return false;
            return isPermitted({
              desiredAction: PERMISSION_ACTIONS.R,
              desiredResource: permissionResource,
              isAdmin: user.isAdmin,
              permissions: user.permissions,
            });
          })
          .map(({ id }) => {
            const link = get(moduleMapping, `${id}.link`);
            const label = get(moduleMapping, `${id}.label`, id);
            const active = get(location, 'pathname').includes(link);
            const icon = `${id}-${active ? 'filled' : 'outline'}`;
            return (
              <DrawerItem
                key={`sidebar-nav-${id}`}
                active={get(location, 'pathname').includes(link)}
                icon={icon}
                label={label}
                link={link}
                open={open}
              />
            );
          })}
      </Box>
    </Box>
  );
}

Drawer.propTypes = {
  signOut: PropTypes.func.isRequired,
  user: PropTypes.shape({
    isAdmin: PropTypes.bool,
    permissions: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

export default Drawer;
