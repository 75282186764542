import React from 'react';
import PropTypes from 'prop-types';
import Button from '@base/Button';

function SubmitButton({ submitting, text }) {
  return (
    <Button mx="auto" my={7} submitting={submitting} type="submit" width="100%">
      {text}
    </Button>
  );
}

SubmitButton.propTypes = {
  submitting: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
};
export default SubmitButton;
