export const AUTH_STATES = {
  LOADING: 'LOADING',
  MY_CHANGE_PASSWORD: 'MY_CHANGE_PASSWORD',
  MY_FORGOT_PASSWORD: 'MY_FORGOT_PASSWORD',
  NEW_PASSWORD_REQUIRED: 'NEW_PASSWORD_REQUIRED',
  SIGNED_IN: 'SIGNED_IN',
  SIGNED_OUT: 'SIGNED_OUT',
};

export const INCORRECT_CREDENTIALS = 'Incorrect username or password.';
export const INVALID_CODE =
  'Invalid verification code provided, please try again.';
