import React from 'react';
import PropTypes from 'prop-types';
import Box from '@base/Box';
import Button from '@base/Button';
import Icon from '@components/Icon';
import { ICON_SIZE, TRANSITION } from '../../utils/constants';

function DrawerItem({ active, icon, label, link, onClick, open }) {
  return (
    <Button
      fontWeight={active ? 'xbold' : 'light'}
      link={link}
      onClick={onClick}
      simple
      sx={{
        ':hover': {
          bg: 'primary',
          color: 'white',
        },
        alignItems: 'center',
        bg: active ? 'white' : 'inherit',
        borderRadius: 4,
        color: active ? 'accentDark' : 'white',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: open ? 'flex-start' : 'center',
        my: 2,
        p: 4,
        transition: TRANSITION,
      }}
      width="100%"
    >
      <Box>
        <Icon height={ICON_SIZE} svg={icon} width={ICON_SIZE} />
      </Box>
      <Box
        display={open ? 'block' : 'none'}
        ml={open ? 4 : 0}
        overflow="hidden"
        sx={{
          textOverflow: open ? 'ellipsis' : 'hidden',
          whiteSpace: 'nowrap',
        }}
      >
        {label}
      </Box>
    </Button>
  );
}

DrawerItem.defaultProps = {
  active: false,
  icon: 'help',
  link: '',
  onClick: () => null,
  open: false,
};

DrawerItem.propTypes = {
  active: PropTypes.bool,
  icon: PropTypes.string,
  label: PropTypes.string.isRequired,
  link: PropTypes.string,
  onClick: PropTypes.func,
  open: PropTypes.bool,
};

export default DrawerItem;
