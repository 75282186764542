import PropTypes from 'prop-types';
import {
  composeValidations,
  email,
  match,
  minLength,
  required,
} from '@utils/validations';
import { Auth } from 'aws-amplify';
import { FORM_ERROR } from 'final-form';
import React, { useState } from 'react';
import { Field, Form as FinalForm } from 'react-final-form';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { useTheme } from 'styled-components';
import { get } from 'lodash';
import Input from '@base/Input';
import Button from '@base/Button';
import FormError from '@components/FormError';
import { minPasswordLength } from '@config';
import Box from '@base/Box';
import useSnackbar from '@utils/use-snackbar';
import { INVALID_CODE } from '@layout/App/Auth/utils/constants';
import SubmitButton from '../SubmitButton';

const labelSx = { color: 'white' };

function PasswordReset({ changePasswordWithCode, setBackToLogin }) {
  const theme = useTheme();
  const [setSucessSnack] = useSnackbar({ color: 'accentDark' });
  const [codeSent, setCodeSent] = useState(false);
  const [em, setEm] = useState('');
  const [verificationCode, setVerificationCode] = useState(null);

  return (
    <>
      {!codeSent && (
        <FinalForm
          onSubmit={async (values) => {
            const val = values.email;
            try {
              await Auth.forgotPassword(val);
              setCodeSent(true);
              setEm(val);
              setSucessSnack(
                `An email was sent to ${val} with a verification code.`
              );
            } catch (e) {
              return {
                [FORM_ERROR]: e.message,
              };
            }
          }}
          render={({ handleSubmit, submitting, submitError }) => (
            <form onSubmit={handleSubmit}>
              <Field
                component={Input}
                disabled={submitting}
                label="Email"
                labelSx={labelSx}
                name="email"
                validate={composeValidations(required, email)}
              />
              <FormError>{submitError}</FormError>
              <Box
                sx={{
                  button: {
                    margin: '2rem 0',
                  },
                }}
              >
                <SubmitButton submitting={submitting} text="Send Code" />
              </Box>
            </form>
          )}
        />
      )}

      {codeSent && (
        <>
          {codeSent && email && !verificationCode && (
            <Box color="grays.3" fontSize={3} fontWeight="light" my={5}>
              If the email <Box as="b">({em})</Box> you entered was correct, you
              will receive an email with a verification code. Enter it below to
              verify your identity.
            </Box>
          )}

          <FinalForm
            onSubmit={async ({ code, newPassword }) => {
              try {
                if (verificationCode) {
                  await changePasswordWithCode(em, code, newPassword);
                  setSucessSnack('Password successfully reset.');
                } else {
                  setVerificationCode(code);
                }
              } catch (e) {
                const errorMessage = e.message;
                if (errorMessage === INVALID_CODE) {
                  setVerificationCode(null);
                }
                return {
                  [FORM_ERROR]: errorMessage,
                };
              }
            }}
            render={({ handleSubmit, submitError, submitting, values }) => (
              <form onSubmit={handleSubmit}>
                {!verificationCode && (
                  <Field
                    component={Input}
                    disabled={submitting}
                    label="Verification Code"
                    labelSx={labelSx}
                    name="code"
                    validate={composeValidations(required)}
                  />
                )}
                {verificationCode && (
                  <>
                    <Field
                      component={Input}
                      disabled={submitting}
                      label="New Password"
                      labelSx={labelSx}
                      name="newPassword"
                      type="password"
                      validate={composeValidations(
                        minLength(minPasswordLength),
                        required
                      )}
                    />
                    <Field
                      component={Input}
                      disabled={submitting}
                      label="Verify Password"
                      labelSx={labelSx}
                      name="verifiedPassword"
                      type="password"
                      validate={composeValidations(
                        match('Passwords', values.newPassword),
                        required
                      )}
                    />
                  </>
                )}
                <FormError>{submitError}</FormError>
                <Box
                  alignItems="center"
                  display="flex"
                  justifyContent="strech"
                  sx={{
                    button: {
                      margin: '2rem 0',
                    },
                  }}
                >
                  <SubmitButton
                    submitting={submitting}
                    text={verificationCode ? 'Reset Password' : 'Next'}
                  />
                  <Box color="white" mx={4}>
                    <CountdownCircleTimer
                      colors={[
                        get(theme, 'colors.accent'),
                        get(theme, 'colors.accentLight'),
                        get(theme, 'colors.errorDark'),
                      ]}
                      colorsTime={[120, 60, 30]}
                      duration={120}
                      initialRemainingTime={180}
                      isPlaying
                      onComplete={() => setCodeSent(false)}
                      size={40}
                      strokeLinecap="rounded"
                      strokeWidth={5}
                    >
                      {({ remainingTime }) => remainingTime}
                    </CountdownCircleTimer>
                  </Box>
                </Box>
              </form>
            )}
          />
        </>
      )}
      <Button
        onClick={() => setBackToLogin()}
        simple
        sx={{
          my: 5,
        }}
      >
        Back to Login
      </Button>
    </>
  );
}

PasswordReset.propTypes = {
  changePasswordWithCode: PropTypes.func.isRequired,
  setBackToLogin: PropTypes.func.isRequired,
};

export default PasswordReset;
